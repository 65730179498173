@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

.fontMagic {
    font-family: 'Yusei Magic' !important;
}

.App {
    text-align: center;
}

* {
    font-family: 'Inter', sans-serif !important;
}

.russo-one {
    font-family: 'Russo One', sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.brand {
    font-family: 'Russo One', sans-serif !important;
}

.ant-layout-header {
    background-color: #f7f9fb;
}

.primary {
    color: #8666ff !important;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

svg {
    display: inline !important;
    vertical-align: baseline !important;
}

.loaderIcon .spin {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

.paymentMethod:hover {
    box-shadow: 1px 1px 15px -7px #8666ff;
    cursor: pointer;
}

.ant-tabs-nav::before {
    border-bottom: none !important;
}

.ant-tabs-tab {
    background-color: #fafbfd !important;
    border: none !important;
    margin-right: 2px !important;
    border-radius: 10px !important;
    padding: 8px 20px !important;
    font-family: 'DM Sans', sans-serif !important;
}

.ant-tabs-tab-active {
    background-color: #f3edff !important;
    border-bottom: none !important;
    font-family: 'DM Sans', sans-serif !important;
}

.ant-tabs-tab:hover {
    color: #876ee4 !important;
    font-family: 'DM Sans', sans-serif !important;
}

.ant-tabs-nav .ant-tabs-tab-active > * {
    color: #876ee4 !important;
    font-family: 'DM Sans', sans-serif !important;
}

.ant-tabs-nav .ant-tabs-tab-active .ant-row .ant-typography {
    color: #876ee4 !important;
    font-family: 'DM Sans', sans-serif !important;
}

.pro-input {
    min-height: 40.2px;
    background-color: rgb(250, 251, 253);
    border: 0.1px solid rgba(172, 142, 253, 0.5);
}

.clickable {
    cursor: pointer;
}
.strong {
    font-weight: 600;
}

.ant-input {
    min-height: 40px;
}

.ant-select-selector {
    min-height: 40px;
}
.ant-select-arrow {
    height: 75% !important;
}

.ant-float-btn-body {
    background-color: #8666ff !important;
}

.long-arrow {
    background: #8666fd;
    height: 3px;
    width: 80%;
    margin: 0 auto;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        background: #8666fd;
        position: absolute;
        height: 3px;
        width: 15px;
    }

    &:before {
        right: -3px;
        bottom: -4px;
        transform: rotate(-45deg);
    }

    &:after {
        right: -3px;
        top: -4px;
        transform: rotate(45deg);
    }
}

.glass {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

.ant-form-item {
    margin-bottom: 5px;
}

/* New Design System */
:root {
    /* Neutrals */
    --neutral-1: #0f172a;
    --neutral-2: #334055;
    --neutral-3: #71717a;
    --neutral-4: #e4e4e7;
    --neutral-5: #f4f4f5;
    --neutral-6: #f1f5f9;
    --neutral-7: #f8fafc;
    --neutral-8: #fafafa;
    --white: #ffffff;

    /* Pinks */
    --pink-1: #fdeef9;
    --pink-2: #fbd6f0;
    --pink-3: #f7b6e5;
    --pink-4: #f494d9;
    --pink-5: #f074cd;
    --pink-6: #ed55c2;
    --pink-7: #c948a5;
    --pink-8: #a83c8a;
    --pink-9: #87306f;
    --pink-10: #6b2657;

    /* Orange */
    --orange-1: #fcf1ed;
    --orange-2: #f7ded3;
    --orange-3: #f1c4b0;
    --orange-4: #eaa98c;
    --orange-5: #e48f69;
    --orange-6: #de7648;
    --orange-7: #bd643d;
    --orange-8: #9e5433;
    --orange-9: #7f4329;
    --orange-10: #643520;

    /* Purple */
    --purple-1: #f3f0ff;
    --purple-2: #e2daff;
    --purple-3: #cbbdff;
    --purple-4: #b39fff;
    --purple-5: #9c82ff;
    --purple-6: #8666ff;
    --purple-7: #7257d9;
    --purple-8: #5f48b5;
    --purple-9: #4c3a91;
    --purple-10: #3c2e73;

    /* Green */
    --green-1: #eef8f4;
    --green-2: #d5efe5;
    --green-3: #b4e3d1;
    --green-4: #91d5bc;
    --green-5: #70c9a7;
    --green-6: #51bd94;
    --green-7: #45a17e;
    --green-8: #3a8669;
    --green-9: #2e6c54;
    --green-10: #245543;

    /* Teal */
    --teal-1: #e9fafb;
    --teal-2: #caf3f5;
    --teal-3: #a0e9ed;
    --teal-4: #74dee5;
    --teal-5: #4bd4dd;
    --teal-6: #23cbd6;
    --teal-7: #1eadb6;
    --teal-8: #199098;
    --teal-9: #14747a;
    --teal-10: #105b60;

    /* Blue */
    --blue-1: #f0f2fe;
    --blue-2: #dbe0fe;
    --blue-3: #bfc7fc;
    --blue-4: #a2adfb;
    --blue-5: #869afa;
    --blue-6: #6b7df9;
    --blue-7: #5b6ad4;
    --blue-8: #4c59b1;
    --blue-9: #3d478e;
    --blue-10: #303870;

    /* Red */
    --red-1: #faecec;
    --red-2: #f4d2d2;
    --red-3: #ebaeae;
    --red-4: #e28989;
    --red-5: #d96565;
    --red-6: #d14343;
    --red-7: #b23939;
    --red-8: #943030;
    --red-9: #772626;
    --red-10: #5e1e1e;

    /* Yellow */
    --yellow-1: #fff7e9;
    --yellow-2: #ffecc9;
    --yellow-3: #ffdd9f;
    --yellow-4: #ffce72;
    --yellow-5: #ffbf47;
    --yellow-6: #ffb11f;
    --yellow-7: #d9961a;
    --yellow-8: #b57e16;
    --yellow-9: #916512;
    --yellow-10: #73500e;
}

/* Neutrals */
.neutral-1 {
    color: var(--neutral-1);
}
.neutral-2 {
    color: var(--neutral-2);
}
.neutral-3 {
    color: var(--neutral-3);
}
.neutral-4 {
    color: var(--neutral-4);
}
.neutral-5 {
    color: var(--neutral-5);
}
.neutral-6 {
    color: var(--neutral-6);
}
.neutral-7 {
    color: var(--neutral-7);
}
.neutral-8 {
    color: var(--neutral-8);
}
.white {
    color: var(--white);
}

.neutral-3-anchor {
    color: var(--neutral-3) !important;
}

.bg-neutral-1 {
    background-color: var(--neutral-1);
}
.bg-neutral-2 {
    background-color: var(--neutral-2);
}
.bg-neutral-3 {
    background-color: var(--neutral-3);
}
.bg-neutral-4 {
    background-color: var(--neutral-4);
}
.bg-neutral-5 {
    background-color: var(--neutral-5);
}
.bg-neutral-6 {
    background-color: var(--neutral-6);
}
.bg-neutral-7 {
    background-color: var(--neutral-7);
}
.bg-neutral-8 {
    background-color: var(--neutral-8);
}
.bg-white {
    background-color: var(--white);
}

/* Pinks */
.pink-1 {
    color: var(--pink-1);
}
.pink-2 {
    color: var(--pink-2);
}
.pink-3 {
    color: var(--pink-3);
}
.pink-4 {
    color: var(--pink-4);
}
.pink-5 {
    color: var(--pink-5);
}
.pink-6 {
    color: var(--pink-6);
}
.pink-7 {
    color: var(--pink-7);
}
.pink-8 {
    color: var(--pink-8);
}
.pink-9 {
    color: var(--pink-9);
}
.pink-10 {
    color: var(--pink-10);
}

.bg-pink-1 {
    background-color: var(--pink-1);
}
.bg-pink-2 {
    background-color: var(--pink-2);
}
.bg-pink-3 {
    background-color: var(--pink-3);
}
.bg-pink-4 {
    background-color: var(--pink-4);
}
.bg-pink-5 {
    background-color: var(--pink-5);
}
.bg-pink-6 {
    background-color: var(--pink-6);
}
.bg-pink-7 {
    background-color: var(--pink-7);
}
.bg-pink-8 {
    background-color: var(--pink-8);
}
.bg-pink-9 {
    background-color: var(--pink-9);
}
.bg-pink-10 {
    background-color: var(--pink-10);
}

/* Orange */
.orange-1 {
    color: var(--orange-1);
}
.orange-2 {
    color: var(--orange-2);
}
.orange-3 {
    color: var(--orange-3);
}
.orange-4 {
    color: var(--orange-4);
}
.orange-5 {
    color: var(--orange-5);
}
.orange-6 {
    color: var(--orange-6);
}
.orange-7 {
    color: var(--orange-7);
}
.orange-8 {
    color: var(--orange-8);
}
.orange-9 {
    color: var(--orange-9);
}
.orange-10 {
    color: var(--orange-10);
}

.bg-orange-1 {
    background-color: var(--orange-1);
}
.bg-orange-2 {
    background-color: var(--orange-2);
}
.bg-orange-3 {
    background-color: var(--orange-3);
}
.bg-orange-4 {
    background-color: var(--orange-4);
}
.bg-orange-5 {
    background-color: var(--orange-5);
}
.bg-orange-6 {
    background-color: var(--orange-6);
}
.bg-orange-7 {
    background-color: var(--orange-7);
}
.bg-orange-8 {
    background-color: var(--orange-8);
}
.bg-orange-9 {
    background-color: var(--orange-9);
}
.bg-orange-10 {
    background-color: var(--orange-10);
}

/* Purple */
.purple-1 {
    color: var(--purple-1);
}
.purple-2 {
    color: var(--purple-2);
}
.purple-3 {
    color: var(--purple-3);
}
.purple-4 {
    color: var(--purple-4);
}
.purple-5 {
    color: var(--purple-5);
}
.purple-6 {
    color: var(--purple-6);
}
.purple-7 {
    color: var(--purple-7);
}
.purple-8 {
    color: var(--purple-8);
}
.purple-9 {
    color: var(--purple-9);
}
.purple-10 {
    color: var(--purple-10);
}

.bg-purple-1 {
    background-color: var(--purple-1);
}
.bg-purple-2 {
    background-color: var(--purple-2);
}
.bg-purple-3 {
    background-color: var(--purple-3);
}
.bg-purple-4 {
    background-color: var(--purple-4);
}
.bg-purple-5 {
    background-color: var(--purple-5);
}
.bg-purple-6 {
    background-color: var(--purple-6);
}
.bg-purple-7 {
    background-color: var(--purple-7);
}
.bg-purple-8 {
    background-color: var(--purple-8);
}
.bg-purple-9 {
    background-color: var(--purple-9);
}
.bg-purple-10 {
    background-color: var(--purple-10);
}

/* Green */
.green-1 {
    color: var(--green-1);
}
.green-2 {
    color: var(--green-2);
}
.green-3 {
    color: var(--green-3);
}
.green-4 {
    color: var(--green-4);
}
.green-5 {
    color: var(--green-5);
}
.green-6 {
    color: var(--green-6);
}
.green-7 {
    color: var(--green-7);
}
.green-8 {
    color: var(--green-8);
}
.green-9 {
    color: var(--green-9);
}
.green-10 {
    color: var(--green-10);
}

.bg-green-1 {
    background-color: var(--green-1);
}
.bg-green-2 {
    background-color: var(--green-2);
}
.bg-green-3 {
    background-color: var(--green-3);
}
.bg-green-4 {
    background-color: var(--green-4);
}
.bg-green-5 {
    background-color: var(--green-5);
}
.bg-green-6 {
    background-color: var(--green-6);
}
.bg-green-7 {
    background-color: var(--green-7);
}
.bg-green-8 {
    background-color: var(--green-8);
}
.bg-green-9 {
    background-color: var(--green-9);
}
.bg-green-10 {
    background-color: var(--green-10);
}

/* Teal */
.teal-1 {
    color: var(--teal-1);
}
.teal-2 {
    color: var(--teal-2);
}
.teal-3 {
    color: var(--teal-3);
}
.teal-4 {
    color: var(--teal-4);
}
.teal-5 {
    color: var(--teal-5);
}
.teal-6 {
    color: var(--teal-6);
}
.teal-7 {
    color: var(--teal-7);
}
.teal-8 {
    color: var(--teal-8);
}
.teal-9 {
    color: var(--teal-9);
}
.teal-10 {
    color: var(--teal-10);
}

.bg-teal-1 {
    background-color: var(--teal-1);
}
.bg-teal-2 {
    background-color: var(--teal-2);
}
.bg-teal-3 {
    background-color: var(--teal-3);
}
.bg-teal-4 {
    background-color: var(--teal-4);
}
.bg-teal-5 {
    background-color: var(--teal-5);
}
.bg-teal-6 {
    background-color: var(--teal-6);
}
.bg-teal-7 {
    background-color: var(--teal-7);
}
.bg-teal-8 {
    background-color: var(--teal-8);
}
.bg-teal-9 {
    background-color: var(--teal-9);
}
.bg-teal-10 {
    background-color: var(--teal-10);
}

/* Blue */
.blue-1 {
    color: var(--blue-1);
}
.blue-2 {
    color: var(--blue-2);
}
.blue-3 {
    color: var(--blue-3);
}
.blue-4 {
    color: var(--blue-4);
}
.blue-5 {
    color: var(--blue-5);
}
.blue-6 {
    color: var(--blue-6);
}
.blue-7 {
    color: var(--blue-7);
}
.blue-8 {
    color: var(--blue-8);
}
.blue-9 {
    color: var(--blue-9);
}
.blue-10 {
    color: var(--blue-10);
}

.bg-blue-1 {
    background-color: var(--blue-1);
}
.bg-blue-2 {
    background-color: var(--blue-2);
}
.bg-blue-3 {
    background-color: var(--blue-3);
}
.bg-blue-4 {
    background-color: var(--blue-4);
}
.bg-blue-5 {
    background-color: var(--blue-5);
}
.bg-blue-6 {
    background-color: var(--blue-6);
}
.bg-blue-7 {
    background-color: var(--blue-7);
}
.bg-blue-8 {
    background-color: var(--blue-8);
}
.bg-blue-9 {
    background-color: var(--blue-9);
}
.bg-blue-10 {
    background-color: var(--blue-10);
}

/* Red */
.red-1 {
    color: var(--red-1);
}
.red-2 {
    color: var(--red-2);
}
.red-3 {
    color: var(--red-3);
}
.red-4 {
    color: var(--red-4);
}
.red-5 {
    color: var(--red-5);
}
.red-6 {
    color: var(--red-6);
}
.red-7 {
    color: var(--red-7);
}
.red-8 {
    color: var(--red-8);
}
.red-9 {
    color: var(--red-9);
}
.red-10 {
    color: var(--red-10);
}

.bg-red-1 {
    background-color: var(--red-1);
}
.bg-red-2 {
    background-color: var(--red-2);
}
.bg-red-3 {
    background-color: var(--red-3);
}
.bg-red-4 {
    background-color: var(--red-4);
}
.bg-red-5 {
    background-color: var(--red-5);
}
.bg-red-6 {
    background-color: var(--red-6);
}
.bg-red-7 {
    background-color: var(--red-7);
}
.bg-red-8 {
    background-color: var(--red-8);
}
.bg-red-9 {
    background-color: var(--red-9);
}
.bg-red-10 {
    background-color: var(--red-10);
}

/* Yellow */
.yellow-1 {
    color: var(--yellow-1);
}
.yellow-2 {
    color: var(--yellow-2);
}
.yellow-3 {
    color: var(--yellow-3);
}
.yellow-4 {
    color: var(--yellow-4);
}
.yellow-5 {
    color: var(--yellow-5);
}
.yellow-6 {
    color: var(--yellow-6);
}
.yellow-7 {
    color: var(--yellow-7);
}
.yellow-8 {
    color: var(--yellow-8);
}
.yellow-9 {
    color: var(--yellow-9);
}
.yellow-10 {
    color: var(--yellow-10);
}

.bg-yellow-1 {
    background-color: var(--yellow-1);
}
.bg-yellow-2 {
    background-color: var(--yellow-2);
}
.bg-yellow-3 {
    background-color: var(--yellow-3);
}
.bg-yellow-4 {
    background-color: var(--yellow-4);
}
.bg-yellow-5 {
    background-color: var(--yellow-5);
}
.bg-yellow-6 {
    background-color: var(--yellow-6);
}
.bg-yellow-7 {
    background-color: var(--yellow-7);
}
.bg-yellow-8 {
    background-color: var(--yellow-8);
}
.bg-yellow-9 {
    background-color: var(--yellow-9);
}
.bg-yellow-10 {
    background-color: var(--yellow-10);
}

/* Typography */
.h1-bold {
    font-size: 32px;
    font-weight: bold;
}

.h1-regular {
    font-size: 32px;
    font-weight: normal;
}

.h2-bold {
    font-size: 28px;
    font-weight: bold;
}

.h2-regular {
    font-size: 28px;
    font-weight: normal;
}

.h3-bold {
    font-size: 25px;
    font-weight: bold;
}

.h3-regular {
    font-size: 25px;
    font-weight: normal;
}

.h4-bold {
    font-size: 22px;
    font-weight: bold;
}

.h4-regular {
    font-size: 22px;
    font-weight: normal;
}

.h5-bold {
    font-size: 20px;
    font-weight: bold;
}

.h5-regular {
    font-size: 20px;
    font-weight: normal;
}

.h6-bold {
    font-size: 18px;
    font-weight: bold;
}

.h6-regular {
    font-size: 18px;
    font-weight: normal;
}

/* Paragraph Styles */
.p-xsmall-regular {
    font-size: 11px;
    font-weight: normal;
}

.p-xsmall-medium {
    font-size: 11px;
    font-weight: 500;
}

.p-small-regular {
    font-size: 12px;
    font-weight: normal;
}

.p-small-medium {
    font-size: 12px;
    font-weight: 500;
}

.p-base-regular {
    font-size: 14px;
    font-weight: normal;
}

.p-base-bold {
    font-size: 14px;
    font-weight: bold;
}

.p-base-semi-bold {
    font-size: 14px;
    font-weight: 600;
}

.p-large-regular {
    font-size: 16px;
    font-weight: normal;
}

.p-large-semi-bold {
    font-size: 16px;
    font-weight: 600;
}

/* Radius */
.rounded-sm {
    border-radius: 0.25rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.rounded-full {
    border-radius: 9999px;
}

/* Shadows */
.shadow {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.shadow-md {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}
.shadow-lg {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

/* Width */
.w-10 {
    width: 10%;
}
.w-20 {
    width: 20%;
}
.w-25 {
    width: 25%;
}
.w-30 {
    width: 30%;
}
.w-40 {
    width: 40%;
}
.w-50 {
    width: 50%;
}
.w-60 {
    width: 60%;
}
.w-70 {
    width: 70%;
}
.w-75 {
    width: 75%;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}

/* Buttons */
.btn {
    background-color: var(--neutral-1);
    transition: box-shadow 0s ease-in-out;
    padding: 8px, 10px, 8px, 10px;
    border-radius: 6px;
}

.btn:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.btn-shadow {
    transition: box-shadow 0s ease-in-out;
    padding: 8px 10px 8px 10px;
    border-radius: 6px;
}

.btn-shadow:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.btn-primary {
    background-color: var(--neutral-1);
    transition: box-shadow 0s ease-in-out;
    padding: 8px, 10px, 8px, 10px;
    border-radius: 6px;
}

.btn-primary:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--neutral-1) !important;
}

.btn-disabled {
    padding: 8px, 10px, 8px, 10px;
    border-radius: 6px;
    pointer-events: none;
    opacity: 0.5;
}

/* Antd Overrides */
label {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    position: relative !important;
    color: var(--neutral-1) !important;
}

.filepond--panel-root {
    background-color: var(--neutral-4) !important;
}

.ant-btn-primary:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.ant-radio-button-wrapper:before {
  background-color: transparent !important
}

.csf-upload > .ant-upload-select {
    width: 100% !important;
}